
/* ============================================ backend ========================================= */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
::selection {
    color: white;
    background: #000000;
  }
body{
    background-color:#EEF0F8;
}

span,p,a {
    font-family: 'Rubik', sans-serif!important;
}

.custom-scrollbar{
    overflow: auto;
}
.custom-scrollbar::-webkit-scrollbar {
    height: 6px;
    width:  6px;
}
/*Track */
.custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
}
/*Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #70707042;
    border-radius: 6px;
    box-shadow: inset 0 0 5px 3px #707070;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #7070708f;
    scrollbar-color: #7070708f rgba(54, 54, 54, 0.56);
}


.header-mobile, .brand-dark .header-mobile {
    background-color: #ffffff!important;
}
.custom_datatable_head{
    font-family: 'Rubik', sans-serif;
    background: #f3f5f9
}
.custom_datatable td, .custom_datatable td span{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color:#2e384d;
}
.custom_datatable.table-striped tbody tr:nth-of-type(odd) {
    background-color: #eff3f6;
}
.custom_datatable.table-hover tbody tr:hover {
    background-color: #eff3f6;
    transform: scale(1.0055);
    font-weight: 500;
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
}
.custom_datatable td.total{
    font-weight: 500;
}
.custom_datatable td span.text-success{
    color:#1bc5bd!important;
}
.selected_stones, .selected_stones span{
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #2e384d;
}
.selected_diamonds_status{
    font-family: 'Rubik', sans-serif;
    font-size: 11px;
    font-weight: normal;
    color: #8798ad;
}
.page_size{
    font-size: 15px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    color: #2e384d;
}
.custom_hr{
    color:#c3c3c3;
}
.table_page_length{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 500!important;
    font-style: italic;
    color: #8798ad!important;
}

.aside-menu .menu-nav .menu-item > .menu-heading, .aside-menu .menu-nav .menu-item > .menu-link {
    transition: all .7s linear;
    white-space: nowrap;
}
.brand-logo{
    transition: all 1s linear;
}
.small_title{
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1.3px;
    color: #b0bac9;
}
.medium_title{
    font-family: Rubik;
    font-size: 18px;
    color: #2e384d;
}
.tracklistdata{
    margin-top: 10px;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 5px;
}
.W_diamond {
    border-radius: 50%;
    background-color: #000000;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.F_diamond {
    border-radius: 50%;
    background-color: #bb314c;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.L_diamond {
    border-radius: 50%;
    background-color: #00c897;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.LF_diamond {
    border-radius: 50%;
    background-color: #f0b33f;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.P_diamond {
    border-radius: 50%;
    background-color: #7CCDF2;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}

.Instock_label{
    color: #1bc5bd!important;
    border-radius: 5px!important;
    background-color: #d4fffd;
    font-family: Rubik!important;
    font-size: 10px!important;
    padding: 1px 6px;
    display: inline-block;
    font-weight: 500!important;
}
.Outofstock_label{
    color: #F64E60!important;
    background-color: #FFE2E5!important;
    border-radius: 5px!important;
    font-family: Rubik;
    font-size: 10px!important;
    padding: 1px 6px;
    display: inline-block;
    font-weight: 500!important;
}
.flagIco{
    height: 16px!important;
    border-radius: 5px;
}
.savedSearchTile{
   height: 412px;
}

.tooltip-inner {
    text-transform: capitalize;
}
.search_btn_check{
    margin-bottom: 0px!important;
}
.search_btn_check > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.outofstock-box .inactive-block {    
    opacity: 0.30;
}

.outofstock-label {
    opacity: 1 !important;
}
.search_btn_check > .selectBox {
    cursor: pointer;
    padding: 8px;
    margin: 1.5px;
    text-align: center;
    background-color: #00000010;
    border: 1px solid #00000015;
    overflow: hidden;
}
.theme2 .search_btn_check .shapeBox.selectBox{
    width: 42px;
    height: 42px;
}
.theme2 .search_btn_check > .selectBox {
    cursor: pointer;
    /* padding: 7px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    text-align: center;
    border-radius: 5px;
    background-color: #00000010;
    border: 1px solid #00000015;
    overflow: hidden;
    transition: all .3s ease-in-out;
}
.theme2 .selectBox:hover {
    box-shadow: 0 8px 6px -6px #777;
    transition: all .3s ease-in-out;    
    transform: scale(1.07);
}

.theme2 .search_btn_check .wColorBox.selectBox,.theme3 .search_btn_check .wColorBox.selectBox{
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .wClarityBox.selectBox,.theme3 .search_btn_check .wClarityBox.selectBox{
    width: 40px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .wFluorBox.selectBox,.theme3 .search_btn_check .wFluorBox.selectBox{
    width: 105px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .wLabBox.selectBox,.theme3 .search_btn_check .wLabBox.selectBox{
    width: 84px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .fancyBox.selectBox , .theme3 .search_btn_check .fancyBox.selectBox{
    width: 115px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme3 .search_btn_check .shapeBox.selectBox{
    width: 70px;
    height: 70px;
}
.theme3 .labTab .search_btn_check > .selectBox ,.theme3 .labTab .search_btn_check > .selectBox{
    background-color: #00000000;
    border: 1px solid #00000000;
}
.theme3 .labTab .search_btn_check > input:checked ~ .selectBox,.theme3 .labTab .search_btn_check > input:checked ~ .selectBox{
    border-radius: 8px;
}
.theme3 .selectBox:hover {
    box-shadow: 0 8px 6px -6px #777;
    transition: all .3s ease-in-out;    
    border-radius: 8px;
    /* transform: scale(1.07); */
}
.theme3 .search_btn_check .shapeBox.selectBox span {
    font-size: 9px;
    padding-top: 7px;
    width: 52px;
}
.search_btn_check .selectBox span{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    line-height:15px;
}
.search_btn_check .shapeBox.selectBox{
    width: 85px;
    height: 70px;
}
.search_btn_check .clarityBox.selectBox{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .labBox.selectBox{
    width: 85px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shortcutBox.selectBox{
    width: 230px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .finishBox.selectBox{
    width: 55px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .fancyBox.selectBox{
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .flourBox.selectBox{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .treatBox.selectBox{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .tingeBox.selectBox{
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shapeBox.selectBox span{
    font-size: 11px;
    padding-top: 5px;
    display: inline-block;
    width: 65px;
}

.search_btn_check > input:checked ~ .selectBox {
    background-color: #000000;
}
.selectBox:hover {
    border:1px solid #000000;
}
.search_btn_check > input:checked ~ .selectBox g [fill] {
    transition: fill 0.3s ease;
    stroke: #fff;
}
.search_btn_check > input:checked ~ .selectBox span {
    transition: fill 0.3s ease;
    color: #fff;
}
.search_btn_check > .selectBox svg{
    height: 36px;
    width: auto;
}
.search_accordion .card .card-header{
    padding: 12px 26px 9px 20px!important;
    background-color: #000000!important;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}
.accordion .card .card-header:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' fill='%23fff'/%3E%3C/svg%3E");
    right: 19px;
    position: absolute;
}
.search_accordion .card {
    border: none!important;
}
.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}
.carat{
    display: none!important;
}
.carat.show{
    display: flex!important;
    flex-wrap: wrap;
}
.expandedDetails{
    height: 455px;
    min-width: 1700px;
    background-color: #fbfbfb!important;
}
/* .custom_datatable{
    min-width: 1850px;
} */
.img_thumb{
    text-align: center;
    list-style-type: none;
    height: 50px;
    width: 50px;
    border: 1px solid #00000010;
    border-radius: 5px;
    margin-bottom:5px;
    overflow: hidden;
    background-color: #bcbebd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_thumb img{
    height: 48px;
    margin: 0 auto;
    object-fit: cover;
}
.img_box{
    margin-left:10px ;
    width: 370px;
    height: 328px;
    background-color: #EBEDF3;
    text-align: center;
}
.img_box img{
    height: 328px;
    margin: 0 auto;
    object-fit: cover;
}

.product_detail .img_box{
    margin-left:10px ;
    width: 100%;
    height: 80%;
    background-color: #EBEDF3;
    text-align: center;
}
.product_detail .img_box img{
    height:100%;
    margin: 0 auto;
    object-fit: cover;
}
.search_result_dropdown .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after{
    content: ""!important;
}
.minHeight{
    min-height: 49px!important;
}

.gridData.card{
    border-radius: 0;
}
.gridData.card:hover {
    z-index: 0;
    box-shadow: 0px 8px 8px -10px rgb(0 0 0 / 68%);
    transition: all .3s ease-in-out;
    transform: scale(1.04);
    border-radius: 8px;
}

.gridData.card .img-fluid {
    height: 228px;
    width: auto;
    object-fit: cover;
}
.table-hover tbody tr.expanded_tr:hover{
    background-color: #fff!important;
}
.table-hover tbody tr.expanded_tr:hover th{
    background-color: #f3f5f9;
}
.sh_expand{
    margin:15px;
}
.sh_expand:hover{
    background-color: #fff;
}

.sh_expand:hover th{
    background-color: #f3f5f9;
}

.table-hover tbody tr:hover {
    background-color: rgba(54,153,255,.12)!important;
}

.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}

.session-modal{
    display: none!important;
}

.session-modal.show{
    display: block!important;
}

.session-modal-button{
    display: none!important;
}

.session-modal-button.show{
    display: block!important;
}
.modal .selectSess.modal-header .close span {
    display: block;
}
.card .MuiPaper-elevation1{
    box-shadow: none!important;
}
.btn-seprator{
    z-index: 2;
    background-color: #fff;
    position: relative;
    display: inline-block;
    padding: 0px 10px;
}
.btn-seprator{
    border-top: 1px solid #00000050;
    display: block;
}
.theme2 .btn-seprator{
    border-top: 1px solid #00000000;
    display: inline-block;
}
.custom-seprator{
    border-top: 1px solid #000000;
    position: absolute;
    top: 16px;
    left: 25%;
    width: 50%;
    z-index: 1;
}
.advanceFilterBtn{
    border-bottom-left-radius: 0.8rem!important;
    border-bottom-right-radius: 0.8rem!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
}
.search-result .MuiTab-root {
    min-width: auto!important;
    min-height: auto!important;
    padding: 10px;
}
.search-result .MuiTabs-root {
    min-height: auto!important;
}
.search-result .MuiTabs-indicator {
    display: none!important;
}
.search-result-list .MuiTypography-root{
    padding: 0px!important;
}
.select_block {
    position: relative;
    margin-left: 800px;
}
.select_block_border{
    align-items: center;
    display: flex;
}
.custom-result-box{
    font-size: 1rem;
    padding: 0.5rem 1.25rem !important;
    border-radius: 5px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
   
}

.hover-card .slide {
    transition: 0.5s;
}

.hover-card .slide.slide1 {
    background-color: #fff;
    z-index: 1;
    transition: 0.7s;
    transform: translateY(0px);
}
  
.hover-card:hover .slide.slide1 {
    transform: translateY(0px);
}

.hover-card .slide.slide2 {
    box-sizing: border-box;
    transition: 0.8s;
    transform: translateY(-50px);
}
  
.hover-card:hover .slide.slide2 {
    transform: translateY(0);
}

 .MultiLingual .MuiOutlinedInput-inputMarginDense {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    color: #000!important;
    text-transform: uppercase;
    font-weight: 600;
}
.MultiLingual .MuiOutlinedInput-notchedOutline {
   border-color: #000!important;
   border-radius: 0;
  
}

.newlogin  .MuiFormLabel-root{
    color:#fff9
}

.newlogin  .MuiInput-underline:before {
    border-bottom: 1px solid rgb(234 234 234 / 42%);
}
.newlogin .MuiInputBase-input {
    color:#fff;
    padding: 8px 8px 10px;
    font-size: 16px!important;
}
.newlogin:-internal-autofill-selected {
    background-image: none !important;
    background-color: rgba(232, 240, 254, 0), rgba(70, 90, 126, 0) !important;
    color: #fff !important;
}

.login-alert.alert.alert-custom.alert-light-danger {
    background-color: #FFE2E500!important;
    border-color: #F64E60!important;
    border-radius: 0;

}
.gridCheck{
    position: absolute;
    top: 1px;
    left: 5px;
    z-index: 1;
}
.cart-symbol{
    width: 20px !important;
    height: 20px !important;
    background-color: #1BC5BD!important;
    color: #fff !important;
    display: flex;
    justify-content: center;
    font-size: 9px !important;
    align-items: center;
    padding-top: 1px;
    top: -6.825px!important;;
    right: -6.825px!important;;
}

.custom-z-index{
    z-index: 2!important;
    position: sticky;
    top:63px
}
.sess-phone .PhoneInput{
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.text-decoration-underline{
    text-decoration: underline;
}
.sess-phone .PhoneInput:focus{
    border-color: #000;
}
.sess-phone input{
    outline: none;
    border: none;
}
.sess-phone input:focus-visible{
    outline: none;
}
.custom-modal .modal-dialog {
    max-width: 90vw; /* Limit modal width for better scaling */
    width: 100%;
  }
  
  .custom-modal .modal-body {
    padding: 0; /* Remove padding for full iframe display */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 70.25%; /* Aspect ratio for 16:9 */
  }
  
  .video-iframe {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .loader-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    justify-content: center;
    align-items: center;
}


@media (max-width: 768px) {
    .form-control {
        font-size: 16px!important; /* Ensures no zoom behavior on mobile devices */
    }
}
