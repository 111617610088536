
.web_login {
    width: 100%;
    /* padding: 80px 0; */
    position: relative;
    background-color: #2b76b9;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%); */
    background-image:url(../Images/ccmode_login_bg2.jpg);
    background-repeat: no-repeat;
    background-size:cover;
}

.web_login h1 {
  font-size: 30px;
  color: #000;
  text-align: center;
}

.wahchang_web_login {
  width: 100%;
  position: relative;
  background-color: #2b76b9;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image:url(../Images/Solitaire.webp);
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}

.wahchang_web_login h1 {
  font-size: 30px;
  color: #000;
  text-align: center;
}



button.cclogin_btn {
    width: 10rem;
    height: auto;
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
  }
  button.cclogin_btn .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    /* background: #fff; */
    /* background-color: rgba(232, 240, 254, 0), rgba(70, 90, 126, 0) !important; */
    background:rgb(232, 240, 254) !important;
    border-radius: 1.625rem;
  }
  button.cclogin_btn .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #282936;
  }
  button.cclogin_btn .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }
  button.cclogin_btn .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #282936;
    border-right: 0.125rem solid #282936;
    transform: rotate(45deg);
  }
  button.cclogin_btn .button_text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: rgb(232, 240, 254);
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }
  button.cclogin_btn:hover .circle {
    width: 100%;
  }
  button.cclogin_btn:hover .circle .icon.arrow {
    background: #282936;
    transform: translate(1rem, 0);
  }
  button.cclogin_btn:hover .button_text {
    color: #282936;
  }

.login_form {
    margin: 0 auto;
    width: 350px;
    /* background-color: rgb(255 255 255 / 18%); */
    /* position: absolute; */
    /* transform: translate(-50%,-50%); */
    /* top: 50%; */
    /* left: 50%; */
    /* border-radius: 10px; */
    /* -webkit-backdrop-filter: blur(1px); */
    /* backdrop-filter: blur(3px); */
    /* border: 1px solid rgb(0 0 0 / 10%); */
    /* box-shadow: 0 0 26px 0px rgb(8 7 16 / 26%); */
    /* padding: 40px 25px; */
}

@media (max-width: 480px) {
    .login_form {
        width: 100%;
        padding: 10px 0;
    }
}
